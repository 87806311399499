

import React, { useState, useEffect } from 'react';

const HomeHero = () => {

  return (
    <div className="homehero">
      <h1>Jacek</h1>
    </div>
  );
};

export default HomeHero;
